.separator {
  height: 20px;
  width: 100%;
  line-height: 1px;
  /* border: 1px solid #ccc; */
  clear: left;
}

/** Autocomplete **/
.autocomplete-suggestions {
  border: 1px solid #999;
  background: #FFF;
  overflow: auto;
}

.autocomplete-suggestion {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-selected {
  background: #F0F0F0;
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399FF;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}

/** bootstrap select **/
.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
}

.bootstrap-select .btn:focus {
  outline: 0;
}

.bootstrap-select a:focus {
  outline: 0;
}

.bootstrap-select .btn {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
}

.escort .box-body .col-sm-2 {
  /*padding-right:0px;*/
}

.escort .box-body .col-sm-1 {
  /*padding-right:0px;*/
}

.escort .remove-row {
  /*padding-top:20px;*/
}

.escorte-form .background-image {
  height: 280px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ccc;
}

/***  Inovice ****/
.modal-extra-lg {
  width: 1200px !important;
}

#invoice-content {
  /*
    .invoice-box table tr td:nth-child(2){
        text-align:right;
    }
    */
}

#invoice-content .invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
  border: 1px solid #ccc;
}

#invoice-content .invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

#invoice-content .invoice-box table td {
  padding: 5px 10px;
  vertical-align: top;
}

#invoice-content .invoice-box table tr.top table td {
  padding-bottom: 20px;
}

#invoice-content .invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

#invoice-content .invoice-box table tr.information table td {
  padding-bottom: 40px;
}

#invoice-content .invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

#invoice-content .invoice-box table tr.details td {
  padding-bottom: 20px;
}

#invoice-content .invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

#invoice-content .invoice-box table tr.item.last td {
  border-bottom: none;
}

#invoice-content .invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

#invoice-content .invoice-label {
  /*background: #eee;*/
  /*border-bottom: 1px solid #ddd;*/
  /*border-right:1px solid #ddd;*/
  font-weight: bold;
  color: #a90c0c;
}

#invoice-content .back-gray {
  background: #eee;
}

#invoice-content .bottom-line td {
  border-bottom: 1px solid #ddd;
}

#invoice-content .total {
  font-size: 18px;
  font-weight: bold;
}

#invoice-content .dates td {
  border-bottom: 1px solid #ddd;
  text-align: left;
  padding: 5px 10px;
  /*border-right:1px solid #ddd;*/
}

#invoice-content .separator {
  clear: both;
  font-size: 1px;
  line-height: 0px;
  height: 1px;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  #invoice-content .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }
  #invoice-content .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}
